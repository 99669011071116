import React, {useState} from 'react';
import { useTranslation } from "react-i18next";
import ButtonLink from '../_shared/ButtonLink';
import LoaderBig from '../_shared/LoaderBig';
import Menu from '../Menu';
import PageTitle from './../_shared/PageTitle';
import ServicesTable from './ServicesTable';
import TeamTable from './TeamTable';

const Team = () => {
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);
  const [radioVal, setRadioVal] = useState('1'); //1-workshops 2-participants;
  const role = JSON.parse(localStorage.getItem("tokens")).roles[0];

  const radioHandler = (e) => {
    setRadioVal(e.currentTarget.value);
  }

  return (
      <>
        <Menu />
        <div className="flex items-center justify-between">
          <PageTitle text={t('myTeam')} />
          <ButtonLink anchor={t('addUser')} href="/add-user" icon="plusThin" />
        </div>

        {role === 'Admin' &&
          <div className="flex mb-5 text-base font-semibold">
            <fieldset className="flex">
              <input id="services" type="radio" name="switch" value="1" className="w-6 h-6 hover:cursor-pointer" checked={radioVal === '1'} onChange={(e) => radioHandler(e)} />
              <label htmlFor="services" className="ml-1 hover:cursor-pointer">{t('services')}</label>
            </fieldset>
            <fieldset className="flex ml-10">
              <input id="participants" type="radio" name="switch" value="2" className="w-6 h-6 hover:cursor-pointer"  checked={radioVal === '2'} onChange={(e) => radioHandler(e)} />
              <label htmlFor="participants" className="ml-1 hover:cursor-pointer">{t('participants')}</label>
            </fieldset>
          </div>
        }

        {loader ?
            <LoaderBig />
            :
            <>
              {role === 'Admin' ?
                <>
                  {radioVal === '1' ?
                      <ServicesTable />
                    :
                      <TeamTable />
                  }
                </>
              :
                <TeamTable />
              }
            </>
        }
      </>
  )
}

export default Team;