import React, { useContext, useEffect, useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import Select from 'react-select';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { defaultStyles, DropdownIndicator } from '../../commons/selectStyles';
import { images } from '../../commons/functions';
import ArrowHeader from "../_shared/ArrowHeader";
import Loader from "../_shared/Loader";
import LoaderBig from '../_shared/LoaderBig';
import PopUp from '../_shared/PopUp';
import JwtInterceptor from "../_shared/JwtInterceptors";
import AuthContext from "../_shared/AuthContext";

const API_URL = process.env.REACT_APP_API_URL;

const cImages = images;

const CompetitionManagementResultUpdate = () => {
  const { t } = useTranslation();
  const { logout } = useContext(AuthContext);
  const { control, register, handleSubmit, formState: { errors } } = useForm();
  const { competition } = useParams();
  const [loader, setLoader] = useState(false);
  const [loaderBig, setLoaderBig] = useState(true);
  const [popUp, setPopUp] = useState(false);
  const [popUpText, setPopUpText] = useState('');
  const [popUpType, setPopUpType] = useState('');
  const [selectStage, setSelectStage] = useState('');
  const [title, setTitle] = useState('');
  const [imageId, setImageId] = useState('');
  const [stages, setStages] = useState({});
  const formData = new FormData();

  useEffect(() => {
    window.scrollTo(0, 0);

    JwtInterceptor
        .get(`${API_URL}Import/editionsForResultsUpload`)
        .then((response) => {
          const stagesTemp = [];

          response.data.forEach(stage => {
            if (stage.competitionId === competition) {
              stagesTemp.push({
                value: stage.id,
                label: `${stage.competitionName} - ${stage.name}`
              });
              setTitle(stage.competitionName);
              setImageId(stage.competitionId);
            }
          });

          setStages(stagesTemp);
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            logout();
          }
          if (error.response.data.errors) {
            const errorTxt = error.response.data.errors.message.map(error => <p>{error}</p>);
            setPopUpText(errorTxt);
          } else {
            setPopUpText(t('errDefault'));
          }
          setPopUpType('error');
        }).finally(() => {
          setLoaderBig(false);
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const onSubmit = async (data) => {
    setLoader(true);

    formData.append('file', data.File[0]);

    JwtInterceptor
        .post(`${API_URL}Import/results?editionId=${data.Stage}`, formData, {headers: {'Content-Type': 'multipart/form-data'}})
        .then(() => {
          setPopUpText(t('dataChangeSuccess'));
          setPopUpType('success');
        })
        .catch(function (error) {
          if (error.response.data.errors) {
            const errorTxt = error.response.data.errors.message.map(error => <p>{error}</p>);
            setPopUpText(errorTxt);
          } else {
            setPopUpText(t('errDefault'));
          }
          setPopUpType('error');
        }).finally(() => {
          setLoader(false);
          setPopUp(true);
        });
  };

  const stageHandler = val => {
    setSelectStage(val.value)
  }

  const popUpCloseHandler = () => {
    setPopUp(false);
  }

  return (
      <>
        <ArrowHeader link="/competition-management" text={t('competitionsManage')} />
        <div>
          <img className="md:absolute" src={`/images/${cImages[imageId]}`} alt="car"/>
          <div className="md:flex">
            <div className="md:w-2/5"></div>
            <div className="md:realtive md:z-10 md:w-3/5">
              <div className="md:min-h-56 mx-m24px md:mx-0 mt-m24px md:mt-32 px-5 py-5 pt-12 pb-8 md:p-10 bg-lightGrayBg md:rounded font-nouvelrbold text-3xl md:text-56px">
                {title}
                <p className="text-3xl">{t('resultsUpdate')}</p>
              </div>
              {loaderBig && <LoaderBig />}
              {stages && stages.length > 0 &&
                <form className="mt-20 text-base" onSubmit={handleSubmit(onSubmit)}>
                  <div className="relative float-label-input isValue md:w-1/2 lg:w-1/2 mb-10">
                    <Controller
                        name="Stage"
                        control={control}
                        defaultValue=""
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                            <>
                              <label htmlFor="Stage" className="absolute top-1.5 left-0 pointer-events-none transition duration-200 ease-in-outbg-white text-sm">{t('stageName')} *</label>
                              <Select
                                  value={stages.find(r => value === r.value)}
                                  options={stages} // Replace with your options array
                                  styles={defaultStyles}
                                  components={{ DropdownIndicator }}
                                  // onChange={val => onChange(roleHandler(val))}
                                  onChange={val => [onChange(val.value), stageHandler(val)]}
                              />
                            </>
                        )}
                    />
                    {errors.Stage && errors.Stage.type === "required" && <span className="text-sm text-error">{t('errFieldRequired')}</span>}
                  </div>
                  <label className="text-sm md:w-1/3 mb-4">
                    <span className="inline-block mr-4 font-nouvelr">{t('fileSelect')}</span>
                    <input type="file" {...register("File", { required: true})} defaultValue="" className={`inline-block outline-none bg-transparent`} />
                    {errors.File && errors.File.type === "required" && <span className="block text-error">{t('errFieldRequired')}</span>}
                  </label>
                  <div>
                    <button type="submit" className={`relative w-fit mt-8 p-4 border font-nouvelrbold text-black transition ease-in-out duration-300 hover:cursor-pointer hover:bg-brand hover:border-brand ${loader ? 'pr-12' : ''}`}>
                      {t('update')}
                      {loader && <Loader />}
                    </button>
                  </div>
                </form>
              }
              <PopUp open={popUp} text={popUpText} type={popUpType} hideFnc={popUpCloseHandler} />
            </div>
          </div>
        </div>
      </>
  );
}

export default CompetitionManagementResultUpdate;