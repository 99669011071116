import React, { useReducer, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { AuthContextProvider } from "./components/_shared/AuthContext";
import { DataReducer, DefaultData } from './commons/data-reducer';
import { Context } from './commons/context';
import Layout from './components/Layout';
import AddUser from './components/AddUser/AddUser';
import Communication from './components/Communication/Communication';
import Competition from "./components/Competition/Competition";
import CompetitionManagement from "./components/CompetitionManagement/CompetitionManagement";
import CompetitionManagementEdit from "./components/CompetitionManagementEdit/CompetitionManagementEdit";
import CompetitionManagementResultUpdate from "./components/CompetitionManagement/CompetitionManagementResultUpdate";
import CompetitionSearch from "./components/CompetitionSearch/CompetitionSearch";
import Competitions from "./components/Competitions/Competitions";
import Contact from "./components/Contact/Contact";
import Cookies from './components/StaticPages/Cookies';
import Informations from "./components/Informations/Informations";
import InformationFiding from "./components/StaticPages/InformationFiding";
import LoginPage from './components/LoginPage/LoginPage';
import Page404 from './components/Page404';
import Profile from './components/Profile/Profile';
import ResetPassword from "./components/ResetPassword/ResetPassword";
import Team from './components/Team/Team';
import './App.css';

function App() {
  const [data, setData] = useReducer(DataReducer, DefaultData);
  const [blurePage, setBlurePage] = useState('none');
  const role = localStorage.getItem("tokens") ? JSON.parse(localStorage.getItem("tokens")).roles[0] : null;

  return (
    <AuthContextProvider>
      <Context.Provider value={[data, setData]}>
        <Routes>
          <Route path="/:ended?" element={<Layout setBlurePage={setBlurePage} />}>
            <Route index element={role && (role === 'Admin' || role === 'MOP') ? <CompetitionSearch /> : <Competitions blurePage={blurePage} />} />
            <Route path="add-user/:id?" element={<AddUser />} />
            <Route path="informations" element={<Informations />} />
            <Route path="information-finding" element={<InformationFiding />} />
            <Route path="communication" element={<Communication />} />
            <Route path="competition/:id" element={<Competition />} />
            <Route path="competition-management" element={<CompetitionManagement />} />
            <Route path="competition-management-edit/:urlId" element={<CompetitionManagementEdit />} />
            <Route path="competition-management-edit/result-update/:competition" element={<CompetitionManagementResultUpdate />} />
            {/*<Route path="competition-search/:ended?" element={<CompetitionSearch />} />*/}
            <Route path="contact" element={<Contact />} />
            <Route path="cookies" element={<Cookies />} />
            <Route path="profile" element={<Profile />} />
            <Route path="team" element={<Team />} />
          </Route>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path='*' element={<Page404 />}/>
        </Routes>
      </Context.Provider>
    </AuthContextProvider>
  );
}

export default App;
