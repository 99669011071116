import React from 'react';
import { useTranslation } from 'react-i18next';
import { reverseDate } from "../../commons/functions";
import ProgressBarItem from "../_shared/ProgressBarItem";

const images = {
  accessories: 'akcesoria.png',
  battery: 'akumulatory.png',
  filters: 'filtry.png',
  videoRepair: 'wideonaprawa.png',
  wipers: 'wycieraczki.png',
  springPremiumPackages: 'wiosennepakietypremium.png',
  airClean: 'czyszczenie_klimatyzacji.png',
  tires: 'opony.png'
}

const CompetitionProgress = ({ competitionId, edition, progressData, title }) => {
  const { t } = useTranslation();

  return (
      <div>
        <img className="md:absolute" src={`/images/${images[competitionId]}`} alt={title} />
        <div className="md:flex">
          <div className="md:w-2/5"></div>
          <div className="md:realtive md:z-10 md:w-3/5 mx-m24px md:mx-0 mt-m24px md:mt-10 px-5 py-5 pt-12 pb-8 md:p-10 bg-lightGrayBg md:rounded">
            {progressData ?
              <ProgressBarItem
                absence={progressData.targetMissing}
                dates={`${reverseDate(progressData.dateFrom)} - ${reverseDate(progressData.dateTo)}`}
                edition={edition}
                goal={progressData.target}
                id={competitionId}
                lastImportDate={progressData.lastImportDate}
                percent={progressData.scorePercentage}
                percentageScore={progressData.percentageScore}
                prize={`${progressData.prize} zł`}
                result={`${progressData.score}`}
                targetType={progressData.targetType}
                title={title}
                type="details"
              />
            :
              <div className="w-full">
                <h3 className="mt-3 md:mt-8 mb-1.5 md:mb-4 font-nouvelrbold text-40px md:text-56px">{title}</h3>
                <p className="md:mb-52 text-xl">{t('competitionInactiveText')}</p>
              </div>
            }
          </div>
        </div>
      </div>
  )
}

export default CompetitionProgress;