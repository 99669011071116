import React, {useContext, useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import { Scrollbars } from 'rc-scrollbars';
import JwtInterceptor from "../_shared/JwtInterceptors";
import AuthContext from "../_shared/AuthContext";
import Icon from '../_shared/Icon';
import LoaderBig from "../_shared/LoaderBig";
import Loader from "../_shared/Loader";
import PopUp from "../_shared/PopUp";

const API_URL = process.env.REACT_APP_API_URL;

const UserRow = ({ user, setPopUp }) => {
  const { logout } = useContext(AuthContext);
  const [isEdit, setIsEdit] = useState(false);
  const [loader, setLoader] = useState(false);
  const [targetVal, setTargetVal] = useState(user.noOfAdvisors);
  const [temp, setTemp] = useState(user.noOfAdvisors);
  const [realoadTable, setReloadTable] = useState(false);

  const editHandler = (close = false) => {
    setIsEdit((prevState) => !prevState);
    if (close) setTargetVal(temp);
  }

  const targetValueHandler = (e) => {
    if (e.currentTarget.value.length > 8) return;
    setTargetVal(e.currentTarget.value);
  }

  const sendTargetHandler = (newValue) => {
    if (newValue || newValue === 0) {
      setLoader(true);

      const payload = {
        "noOfAdvisors": newValue,
        "recalculateTargets": true
      }

      JwtInterceptor
          .post(`${API_URL}Services/${user.externalId}/employees`, payload)
          .then((response) => {
            setIsEdit((prevState) => !prevState);
            setTemp(newValue);
            setReloadTable(true);
          })
          .catch(function (error) {
            setPopUp(true);
            setTargetVal(temp);
            if (error.response.status === 401) {
              logout();
            }
          })
          .finally(() => {
            setLoader(false);
          });
    }
  }

  return (
      <tr className="even:bg-tableEven">
        <td className="px-2 py-4">{user.name}</td>
        <td className="px-2 py-4">{user.chief}</td>
        <td className="px-2 py-4 text-center">
          <form className="flex items-center whitespace-nowrap">
            <input name="target" type="number" value={targetVal} disabled={!isEdit} className={`inline-block w-20 outline-none text-right bg-white border-b border-solid ${isEdit ? 'border-formBorder' : 'border-white'}`} onChange={(e) => targetValueHandler(e)} />
              {isEdit ?
                  <>
                    {loader ?
                        <Loader />
                        :
                        <Icon id="check" clsWrap="shrink-0 ml-3 transform transition duration-y hover:cursor-pointer hover:scale-125" onClickFnc={() => sendTargetHandler(targetVal)} />
                    }
                    <Icon id="x" clsWrap="shrink-0 transform transition duration-y hover:cursor-pointer hover:scale-125" onClickFnc={() => editHandler(true)} />
                  </>
                  :
                  <Icon id="edit" clsWrap="shrink-0 ml-3 hover:cursor-pointer" onClickFnc={editHandler} />
              }
          </form>
        </td>
        <td className="px-2 py-4">{user.street} / {user.postcode} {user.city}</td>
      </tr>
  );
}

const ServicesTable = () => {
  const { t } = useTranslation();
  const { logout } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [loader, setLoader] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [popUpText, setPopUpText] = useState(t('errDefault'));
  const scrollbarHeight = window.innerHeight - 290;
  let usersRow = [];

  useEffect(() => {
    setLoader(true);

    const loadUser = () => {
      JwtInterceptor
          .get(`${API_URL}Services`)
          .then((response) => {
            setUsers(response.data);
          })
          .catch(function (error) {
            if (error.response.status === 401) {
              logout();
            }
            if (error.response.data.errors) {
              const errorTxt = error.response.data.errors.message.map(error => <p>{error}</p>);
              setPopUpText(errorTxt);
            } else {
              setPopUpText(t('errDefault'));
            }
            setPopUp(true);
          }).finally(() => {
            setLoader(false);
      });
    }

    loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const popUpCloseHandler = () => {
    setPopUp(false);
  }

  if (users && users.length > 0) usersRow = users.map(user => <UserRow user={user} setPopUp={setPopUp} key={user.id} />);

  return (
      <Scrollbars style={{ maxWidth: '100%', height: scrollbarHeight }} autoHide={false}>
        <table className="w-full">
          <thead className="w-full mt-4 text-base">
          <tr className="font-semibold text-left bg-black text-white">
            <th className="px-2 py-5 align-middle">{t('service')}</th>
            <th className="px-2 py-5 align-middle">{t('serviceBoss')}</th>
            <th className="px-2 py-5 align-middle">{t('employeesNumber')}</th>
            <th className="px-2 py-5 align-middle">{t('serviceAddress')}</th>
          </tr>
          </thead>
          <tbody className="text-sm text-tableText">
          {usersRow}
          </tbody>
        </table>
        {loader && <LoaderBig />}
        <PopUp open={popUp} text={popUpText} type='error' hideFnc={popUpCloseHandler} />
      </Scrollbars>
  );
}

export default ServicesTable;